var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-container",class:{'faded-in': _vm.pageLoaded }},[_c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',{staticClass:"links"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
				el: '#tinynotepad',
				duration: 500,
				easing: 'ease',
				offset: 0,
				force: true,
				cancelable: true
			}),expression:"{\n\t\t\t\tel: '#tinynotepad',\n\t\t\t\tduration: 500,\n\t\t\t\teasing: 'ease',\n\t\t\t\toffset: 0,\n\t\t\t\tforce: true,\n\t\t\t\tcancelable: true\n\t\t\t}"}],attrs:{"href":"#"}},[_vm._v("Notepad")]),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
				el: '#tinystopwatch',
				duration: 500,
				easing: 'ease',
				offset: 0,
				force: true,
				cancelable: true
			}),expression:"{\n\t\t\t\tel: '#tinystopwatch',\n\t\t\t\tduration: 500,\n\t\t\t\teasing: 'ease',\n\t\t\t\toffset: 0,\n\t\t\t\tforce: true,\n\t\t\t\tcancelable: true\n\t\t\t}"}],attrs:{"href":"#"}},[_vm._v("Stopwatch")]),_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
				el: '#contact',
				duration: 500,
				easing: 'ease',
				offset: 0,
				force: true,
				cancelable: true
			}),expression:"{\n\t\t\t\tel: '#contact',\n\t\t\t\tduration: 500,\n\t\t\t\teasing: 'ease',\n\t\t\t\toffset: 0,\n\t\t\t\tforce: true,\n\t\t\t\tcancelable: true\n\t\t\t}"}],staticClass:"contact-btn",attrs:{"href":"#"}},[_vm._v("Contact")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',[_c('b',[_vm._v("TinyApps")])])])}]

export { render, staticRenderFns }