import { render, staticRenderFns } from "./Imprint.vue?vue&type=template&id=169b8eda&scoped=true&"
import script from "./Imprint.vue?vue&type=script&lang=js&"
export * from "./Imprint.vue?vue&type=script&lang=js&"
import style0 from "./Imprint.vue?vue&type=style&index=0&lang=css&"
import style1 from "./Imprint.vue?vue&type=style&index=1&id=169b8eda&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "169b8eda",
  null
  
)

export default component.exports