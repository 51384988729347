<template>
	<header>
		<Menu />
		<!-- <parallax :speed-factor="0.5" direction="down" :section-height="100">
			<div class="background-wrapper"></div>
		</parallax> -->
		<!-- <div class="content-wrapper">
			<div class="wrapper">
				fff
			</div>
		</div> -->
		<!-- <Menu/> -->
	</header>
</template>

<script>
import Parallax from 'vue-parallaxy'
import Menu from '@/components/Menu.vue'

export default {
	name: 'Main',
	components: {
		Menu,
		// eslint-disable-next-line vue/no-unused-components
		Parallax,
	},
	data() {
		return {
			layer1: {
				transform: '',
			},
			layer2: {
				transform: '',
			},
			pageLoaded: false,
			animationDone: false,
		}
	},
	methods: {
		handleScroll() {
			if (!this.animationDone) {
				return
			}
			// alert("percentage: " + (window.scrollY/window.innerHeight)*100);
			let scrollProgress = window.scrollY / (window.innerHeight * 0.75)
			if (scrollProgress > 1) {
				scrollProgress = 1
			}
			if (scrollProgress < 0) {
				scrollProgress = 0
			}

			this.layer1.transform = `translate(0px, ${Math.floor(scrollProgress * 60)}px)`
			this.layer2.transform = `translate(0px, ${Math.floor(scrollProgress * 30)}px)`
			// document.getElementById('layer-1').
		},
	},
	created() {
		window.addEventListener('scroll', this.handleScroll)
	},
	mounted() {
		// var that = this;
		setTimeout(() => {
			this.pageLoaded = true
		}, 200)
		setTimeout(() => {
			this.animationDone = true
		}, 1200)
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll)
	},
}
</script>

<style lang="scss">
$primaryColor: #00A1B5;
header {
	margin: 0;
	padding: 0;
	// clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	// height: 100vh;
	min-height: 175px;
	position: relative;

	.content-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 30;

		> .wrapper {
			height: 100%;
			color: #eee;
			display: flex;
			/* align-content: center; */
			justify-content: flex-start;
			align-items: center;
			padding: 0 25px;

			h1 {
				display: block;
				width: 65%;
				font-size: 35px;
			}
		}
		z-index: 1;
		// pointer-events: none;
		// user-select: none;
	}

	svg {
		width: 100%;
		.layer1 {
			// transition: .05s transform;
			transform: translate(0px, 0px);
		}

		.layer {
			transition: 1s transform, .8s opacity .2s;
		}

		&.finished-fade-in {
			.layer {
				transition: 0.3s transform;
			}
		}

		&:not(.faded-in) {
			.layer {

				opacity: 0;
				transform-origin: bottom center;

				&.layer-1 {
					transform: scale(0.8) translate(40px, 0px);
				}

				&.layer-2 {
					transform: scale(0.8) translate(20px, 0px);
				}

				&.layer-3 {
					transform: scale(0.8);
				}

			}
		}

		// &.fade-in {
		// 	transform: scale(1);
		// 	&:first-child {
		// 		transform: translate(0px, 0px);
		// 	}
		// 	&:nth-child(2) {
		// 		transform: translate(0px, 0px);
		// 	}
		// }
		// &:hover {
		// 	.layer1 {
		// 		transform: translate(0px, 40px);
		// 	}
		// 	.layer2 {
		// 		transform: translate(0px, 20px);
		// 	}
		// }
	}
}
.st0{enable-background:new;}
.st1{fill:none;stroke:#FFFFFF;stroke-width:2.405;}
.st2{fill:none;stroke:$primaryColor;stroke-width:2.405;}
.st3{fill:none;stroke:$primaryColor;stroke-width:2.405;}

</style>
