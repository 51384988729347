<template>
	<div>
		<!-- <div
			id="about"
			class="about fade-in"
			v-bind:class="{'faded-in': secondsPassed > 0.05 }"
			style="background-color: #f5cf02;"
		>
			<div class="wrapper">
				<p style="color: #000;">
					To support people who are suffering from the Russian attack/war in<br />
					the Ukraine, we are donating 50% of all proceeds from app and<br />
					in-app purchases this month to humanitarian organizations.<br /><br />
					<b style="color: #0158b5;">#StandWithUkraine</b>
				</p>
			</div>
			<Menu/>
		</div><br /> -->
		<div class="about fade-in" id="about" v-bind:class="{'faded-in': secondsPassed > 0.25 }">
			<div class="wrapper">
				<h2>We create simple yet powerful macOS apps that live in your menu bar.</h2>
			</div>
			<!-- <Menu/> -->
		</div>
	</div>
</template>

<script>
export default {
	name: 'About',
	components: {},
	data() {
		return {
			secondsPassed: 0.0,
		}
	},
	mounted() {
		setInterval(() => {
			this.secondsPassed += 0.25
		}, 250)
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$primaryColor: #00A1B5;

.about {
	background: #fff;
	text-align: center;
	padding: 50px 0;//40px 0 60px 0;

	h2 {
		color: #000;
		margin: 30px 0;
	}

	p {
		margin: 30px 0 50px 0;
		// color: #fff;
		color: #5F5F5F;
		font-size: 14pt;
		text-align: center;
		// font-weight: bold;
		@media (max-width: 500px) {
			font-size: 12pt;
		}
	}

	.menu-btn {
		color: #000;
	}

	.full-menu-container {
		.full-menu {
			background: #222729;
			a {
				color: #fff;
			}
		}
	}
}
</style>
