// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars, faArrowDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueResource from 'vue-resource'
import router from './router'
import App from './App.vue'

const VueScrollTo = require('vue-scrollto')

Vue.use(VueScrollTo)
Vue.use(VueResource)

library.add(faBars)
library.add(faArrowDown)
library.add(faTimes)

Vue.component('fa', FontAwesomeIcon)

Vue.config.productionTip = false

let itemQueueCount = 0

Vue.directive('infocus', {
	isLiteral: true,
	inserted: (el, binding) => {
		el.classList.add('fade-in')
		const f = () => {
			const rect = el.getBoundingClientRect()

			if (rect.top <= window.innerHeight) {
				// eslint-disable-next-line no-plusplus
				itemQueueCount++
				console.log(itemQueueCount)
				setTimeout(() => {
					el.classList.add(binding.value)
					if (itemQueueCount > 1) {
						// eslint-disable-next-line no-plusplus
						itemQueueCount--
					}
				}, 150 + (itemQueueCount * 50))

				window.removeEventListener('scroll', f)
			}
		}
		window.addEventListener('scroll', f)
		setTimeout(() => {
			f()
		}, 100)
	},
})

/* eslint-disable no-new */
new Vue({
	el: '#app',
	router,
	components: { App },
	template: '<App/>',
})
