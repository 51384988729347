/* eslint-disable import/extensions */
import Vue from 'vue'
import Router from 'vue-router'
import Base from '@/components/Base'
import Imprint from '@/components/Imprint'
import Privacy from '@/components/Privacy'
import Error from '@/components/Error'

Vue.use(Router)

export default new Router({
	routes: [
		{
			path: '/',
			name: 'Index',
			component: Base,
		},
		{
			path: '/imprint/',
			name: 'Imprint',
			component: Imprint,
		},
		{
			path: '/privacy/',
			name: 'Privacy',
			component: Privacy,
		},
		{
			path: '*',
			name: '404',
			component: Error,
		},
	],
	mode: 'history',
	hashbang: false,
})
