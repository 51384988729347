<template>
	<div id="app">
		<Header/>
		<About/>
		<TinyNotepad/>
		<TinyStopwatch/>
		<Contact/>
	</div>
</template>

<script>
import Header from '@/components/Header.vue'
import About from '@/components/About.vue'
import TinyNotepad from '@/components/TinyNotepad.vue'
import TinyStopwatch from '@/components/TinyStopwatch.vue'
import Contact from '@/components/Contact.vue'

export default {
	name: 'Base',
	components: {
		Header,
		About,
		TinyNotepad,
		TinyStopwatch,
		Contact,
	},
}
</script>

<style lang="scss">
body {
	padding: 0;
	margin: 0;
	background: #262D35;
	color: #fff;
}
#app {
	font-family: proxima-nova, 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	padding: 0;
	font-weight: 400;
	font-style: normal;
}
.wrapper {
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1200px;
}
h1 {
	/* font-family: acumin-pro-wide, sans-serif; */
	font-weight: 900;
	/* font-style: italic; */
	font-size: 25pt;
	color: #fff;
	margin: 50px 0;
	letter-spacing: 4px;
}

.fade-in {
	transition: .5s opacity, .5s transform;
	opacity: 0;
	transform: translateY(15px);
	&.faded-in {
		transform: translateY(0px);
		opacity: 1;
	}
}

.mr-5 {
	margin-right: 5px;
}
</style>
