<template>
	<div id="app">
		<div class="imprint">
			<div class="wrapper">
				<h1>Imprint</h1>
				<p>
					Dion Purushotham<br />
					Kassenberg 49<br />
					45479 Mülheim an der Ruhr<br />
					<b>hello@dion.codes</b>
				</p>
				<p><a href="/">Go back</a></p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Imprint',
}
</script>

<style>
body {
	padding: 0;
	margin: 0;
	background: #262D35;
	color: #fff;
}
</style>

<style lang="scss" scoped>
$primaryColor: #00A1B5;
$gray: #51575d;

#app {
	font-family: proxima-nova, 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	margin: 0;
	padding: 0;
	font-weight: 400;
	font-style: normal;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	height: 100vh;
}
.wrapper {
	margin: 0 auto;
	padding: 0 15px;
	max-width: 1200px;
}

h1 {
	font-family: acumin-pro-wide, sans-serif;
	font-weight: 900;
	font-style: italic;
	font-size: 21pt;
	color: #fff;
	margin: 50px 0;
	letter-spacing: 4px;
	text-align: center;
}

.imprint {
	background: transparent;

	color: #fff;
	// min-height: 100vh;
	box-sizing: border-box;
	text-align: center;
}

p {
	text-align: center;
}

a {
	color: #fff;
	text-decoration: none;
	font-weight: bold;
	transition: .3s padding, .3s border, .3s color, .3s margin;
	padding-bottom: 10px;

	&:hover, &:focus {
		color: $primaryColor;
		border-bottom: 2px solid $primaryColor;
		padding-bottom: 0;
		margin-bottom: 0;
	}
}

</style>
