<template>
	<div
		id="tinynotepad"
		class="app-section dark fade-in"
		:class="{'faded-in': secondsPassed > 0.5 }"
	>
		<h1>TinyNotepad</h1>
		<div class="wrapper flex fade-in" v-infocus="'faded-in'">
			<img src="@/assets/screenshots/tinynotepad-popup.png" style="" />
			<div>
				<h2>Useful in any situation.</h2>
				<p>TinyNotepad lets you take quick notes, temporarily store clipboard contents,
					code snippets or other information you use often.
					The notes are easily accessible at any time,
					can be exported and displayed in different fonts.</p>
			</div>
		</div>
		<div class="wrapper flex fade-in" v-infocus="'faded-in'">
			<div>
				<h2>Do you prefer windows?</h2>
				<p>Switch between the default popup and a windowed mode.</p>
			</div>
			<img src="@/assets/screenshots/tinynotepad-windowed.png" style="" />
		</div>
		<div class="wrapper flex fade-in" v-infocus="'faded-in'">
			<img src="@/assets/screenshots/tinynotepad-settings.png" style="" />
			<div>
				<h2>Customizable.</h2>
				<p>
					Change the font, popup size and set your own keyboard
					shortcut to toggle TinyNotepad quickly at any time.</p>
			</div>
		</div>
		<a class="download" href="https://apps.apple.com/de/app/tinynotepad/id1449415412?mt=12" target="_blank"><img src="@/assets/macappstore_dark.svg" /></a>
	</div>
</template>

<script>
export default {
	name: 'TinyNotepad',
	components: {},
	data() {
		return {
			secondsPassed: 0.0,
		}
	},
	mounted() {
		setInterval(() => {
			this.secondsPassed += 0.25
		}, 250)
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$primaryColor: #00A1B5;

::selection {
	background: $primaryColor;
	color: #fff;
}

.mt-100 {
	margin-top: 100px;
}

.app-section {
	padding: 50px 0 150px 0;
	background: transparent;
	text-align: center;

	h1 {
		color: #fff;
	}

	.wrapper.flex {
		display: flex;
		justify-content: center;
		align-items: center;

		@media(max-width: 600px) {
			flex-wrap: wrap;
			div {
				order: 1;
				min-width: 350px;
			}
			img {
				order: 2;
				min-width: 350px;
			}
		}

		img {
			flex-basis: 50%;
			max-width: 50%;
		}

		div {
			flex-basis: 50%;
			text-align: left;

			p {
				line-height: 1.25;
				font-size: 15pt;
				font-weight: 600;
				color: rgb(180, 180, 180);
			}
		}
	}

}

a.download {
	display: block;
	margin: 0 auto;
	margin-top: 50px;
}
</style>
