<template>
	<!-- eslint-disable max-len -->
	<div class="contact" id="contact" v-infocus="'faded-in'">
		<div class="wrapper">
			<h1 v-infocus="'faded-in'">GET IN TOUCH</h1>
			<p class="subline" v-infocus="'faded-in'">Got any questions or feature requests?<br />Feel free to contact us at any time.</p>
			<p v-show="timeout" class="error">You have already submitted a message recently. Please wait {{ minutesLeft }} minutes before trying again.</p>
			<input type="text" placeholder="Name" v-model="name" v-if="!success" required @change.prevent="contentChanged = true" v-infocus="'faded-in'" />
			<p v-show="!email.valid" class="error">Please enter a valid email address:</p>
			<input type="text" placeholder="Email Address" v-model="email.value" v-if="!success" @change.prevent="contentChanged = true" :class="{ email , error: !email.valid }" v-infocus="'faded-in'" />
			<input type="text" placeholder="Phone (optional)" v-model="phone" v-if="!success" @change.prevent="contentChanged = true" v-infocus="'faded-in'" />
			<p v-show="!message.valid" class="error">Please enter a message (at least 20 characters):</p>
			<textarea placeholder="Message" v-model="message.text" v-if="!success" @change.prevent="contentChanged = true" v-infocus="'faded-in'" :class="{ error: !message.valid }"></textarea>
			<p v-if="!success" class="small" v-infocus="'faded-in'" :class="{ red: privacyNotice && !privacyAccepted }"><input type="checkbox" v-model="privacyAccepted" value="yes" /> I accept the <a href="/privacy/" class="inline" target="_blank">privacy policy</a>.</p>
			<input type="submit" :value="submitting ? 'Sending...' : 'Submit'" v-on:click="sendMessage" v-if="!success" v-infocus="'faded-in'" />
			<p v-show="mailError" class="error">Unfortunately an error occured while sending your message.<br />Please contact us directly by sending an email to hello@tinyapps.de!</p>
			<p v-show="success"><b>Hey {{ name }}</b>!<br />
			Thanks for your message. We'll try to look into it as soon as possible.
			</p>
			<!-- <p v-show="submitting">Please wait while your message is being sent...</p> -->
		</div>
		<div class="wrapper" style="margin-top: 100px;" v-infocus="'faded-in'">
			<p><b><a href="mailto:hello@tinyapps.de" class="no-style"><i class="fas fa-envelope mr-5"></i> hello@tinyapps.de</a></b></p>
			<p><a href="https://dion.software/" target="_blank">dion.software</a> - <a href="/imprint/">Imprint</a> - <a href="/privacy/">Privacy Policy</a></p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Contact',
	data() {
		return {
			name: '',
			email: {
				value: '',
				valid: true,
			},
			phone: '',
			message: {
				text: '',
				valid: true,
			},
			submitted: false,
			submitting: false,
			success: false,
			mailError: false,
			timeout: false,
			minutesLeft: 0,
			contentChanged: false,
			privacyAccepted: false,
			privacyNotice: false,
			submitValue: 'Submit',
		}
	},
	methods: {
		sendMessage() {
			if (!this.privacyAccepted) {
				this.privacyNotice = true
				return
			}
			this.privacyNotice = false
			this.submitting = true
			this.mailError = false
			this.email.valid = true
			this.message.valid = true
			setTimeout(() => {
				this.$http.post(
					'https://tinyapps.de/api/contact-form.php',
					{
						name: this.name,
						email: this.email.value,
						message: this.message.text,
						phone: this.phone,
					},
				).then((response) => {
					this.submitting = false
					const json = response.body
					if (json.status === 'success') {
						this.success = true
						this.email.valid = true
						this.message.valid = true
						this.mailError = false
						this.contentChanged = false
					} else if (json.error === 'email') {
						this.email.valid = false
					} else if (json.error === 'message') {
						this.message.valid = false
					} else if (json.error === 'timeout') {
						this.timeout = true
						this.minutesLeft = json.minutesleft
					} else if (json.error === 'mail_error') {
						this.mailError = true
					}
				}).catch(() => {
					this.submitting = false
					this.mailError = true
				})
			}, 500)
		},
	},
	beforeRouteLeave(to, from, next) {
		// eslint-disable-next-line no-alert
		if (this.contentChanged && !window.confirm('Do you want to leave this website? Your contact form input will be lost.')) {
			next(false)
		} else {
			next()
		}
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$primaryColor: #00A1B5;
$gray: #51575d;
.contact {
	padding: 60px 0 100px 0;
	background: transparent;
	text-align: center;
	color: #fff;
	min-height: 100vh;
	box-sizing: border-box;

	h1 {
		margin-bottom: 20px;
	}

	p.subline {
		margin-bottom: 50px;
	}

	.menu-btn {
		color: #fff;
	}

	input:not([type="checkbox"]), textarea {
		background: rgb(50, 59, 70);
		color: #fff;
		padding: 13px 20px;
		box-sizing: border-box;
		border: 2px solid #262D35;
		box-sizing: border-box;
		outline: none;
		min-width: 150px;
		display: block;
		margin: 15px auto;
		width: 80%;
		max-width: 700px;
		text-align: center;
		font-family: inherit;
		font-size: 11pt;
		border-radius: 0px;
		-webkit-appearance: none;

		&.error {
			border: 2px solid #b10b16;
		}
		@media(max-width: 500px) {
			width: 95%;
		}
	}

	input[type="checkbox"] {
		-webkit-appearance: none;
		border: 2px solid $primaryColor;
		background:transparent;
		outline: none;
		width: 20px;
		height: 20px;
		margin: 5px;
		margin-left: 10px;
		padding: 0;
		vertical-align: middle;
		cursor: pointer;
		border-radius: 0px;

		&:checked {
			background: $primaryColor;
		}
	}

	::placeholder {
		color: $gray;
	}

	input {
		&[type="submit"] {
			background: #fff;
			color: #000;
			border: none;
			font-weight: bold;
			flex-basis: calc(50% - 6px);
			flex-grow: 0;
			cursor: pointer;
			transition: .2s background, .5s opacity, .5s transform;
			border-radius: 0px;
			-webkit-appearance: none;

			&:hover {
				background: $primaryColor;
				color: #fff;
			}

		}
	}
	textarea {
		min-height: 150px;
	}

	.wrapper {
		padding: 0 25px;
	}

	@media (min-width: 700px) {
		.two-cols {
			display: flex;
			.col {
				margin: 25px;
				flex-basis: 50%;
			}
		}
		.wrapper {
			padding: 0;
		}
	}

	a {
		color: #fff;
		// border-bottom: 1px solid $primaryColor;
		font-weight: bold;
		cursor: pointer;
		text-decoration: none;
		margin: 5px;
		font-size: 10pt;
		transition: .3s padding, .3s border, .3s color, .3s margin;
		padding-bottom: 10px;

		&:hover, &:focus {
			color: $primaryColor;
			border-bottom: 2px solid $primaryColor;
			padding-bottom: 0;
			margin-bottom: 0;
		}

		&.inline {
			font-size: inherit;
			color: inherit;
			font-weight: bold;
			text-decoration: underline;
			margin: 0;
			padding: 0;
			&:hover {
				border: none;
			}
		}
	}

	p.error {
		color: #b10b16;
		// padding: 5px 10px;
		width: 80%;
		max-width: 700px;
		box-sizing: border-box;
		margin: 5px auto;
		// margin-bottom: -10px;
		// background: #b10b16;
		box-sizing: border-box;
		@media(max-width: 500px) {
			width: 95%;
		}
	}

	p.small {
		color: #888;
		font-size: 8pt;
	}

	p.red {
		color: #b10b16;
		input[type="checkbox"] {
			border: 2px solid #b10b16;
		}
	}

}

a.no-style {
	color: inherit;
	text-decoration: none;
	border: none;
	font-size: inherit;
	font-family: inherit;
	cursor: pointer;
}
</style>
