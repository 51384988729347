<template>
	<div class="menu-container" v-bind:class="{'faded-in': pageLoaded }">
		<div class="wrapper">
			<div>
				<p><b>TinyApps</b></p>
			</div>
			<div class="links">
				<a href="#" v-scroll-to="{
					el: '#tinynotepad',
					duration: 500,
					easing: 'ease',
					offset: 0,
					force: true,
					cancelable: true
				}">Notepad</a>
				<a href="#" v-scroll-to="{
					el: '#tinystopwatch',
					duration: 500,
					easing: 'ease',
					offset: 0,
					force: true,
					cancelable: true
				}">Stopwatch</a>
				<a href="#" class="contact-btn" v-scroll-to="{
					el: '#contact',
					duration: 500,
					easing: 'ease',
					offset: 0,
					force: true,
					cancelable: true
				}">Contact</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Menu',
	data() {
		return {
			pageLoaded: false,
		}
	},
	mounted() {
		setTimeout(() => {
			this.pageLoaded = true
		}, 700)
	},
}
</script>

<style lang="scss">
$primaryColor: #00A1B5;

.menu-container {
	position: absolute;
	top: 50px;
	left: 0;
	right: 0;
	text-align: left;
	font-size: 16pt;
	z-index: 42;

	transition: .5s opacity;

	&:not(.faded-in) {
		opacity: 0;
	}

	.wrapper {
		padding: 0 25px;
		display: flex;
		align-items: center;
		div {
			flex-grow: 1;
		}
	}

	.links {
		text-align: right;
		@media(max-width: 550px) {
			a:not(.contact-btn) {
				display: none;
			}
		}
		a {
			color: #fff;
			text-decoration: none;
			margin: 5px 15px;
			text-transform: uppercase;
			font-size: 12pt;
			transition: .3s padding, .3s border, .3s color, .3s margin, .3s background;
			padding-bottom: 10px;

			&:last-child {
				margin-right: 0;
			}

			&:hover, &:focus {
				color: $primaryColor;
				border-bottom: 2px solid $primaryColor;
				padding-bottom: 0;
				margin-bottom: 0;
			}

			&.contact-btn {
				padding: 10px 20px;
				margin: 0 15px;
				color: #000;
				background: #fff;
				border: none;
				font-weight: bold;
				&:hover {
					color: #fff;
					// border: 2px solid $primaryColor;
					background: $primaryColor;
				}
			}
		}
	}
}
</style>
