<template>
	<div class="app-section light fade-in" id="tinystopwatch" v-infocus="'faded-in'">
		<h1>TinyStopwatch</h1>
		<div class="wrapper flex fade-in" v-infocus="'faded-in'">
			<div>
				<h2>Track anything.</h2>
				<p>Easily keep track of the time you spent on work or other activities.
					The passed time is always visible right in your menu bar.
					Access the popup for starting, pausing or
					stopping your timer with a simple click on the menu bar.</p>
			</div>
			<img
				src="@/assets/screenshots/tinystopwatch-popup.png"
				style="margin: 2rem 0 2rem 2rem; border-radius: 1rem;"
			/>
		</div>
		<div class="wrapper flex fade-in" v-infocus="'faded-in'">
			<img
				src="@/assets/screenshots/tinystopwatch-windowed-2.png"
				style="margin: 2rem 2rem 2rem 0; border-radius: 1rem;"
			/>
			<div>
				<h2>Of course there is a windowed mode, too.</h2>
				<p>Switch between the default popup and a window that can be moved anywhere.</p>
			</div>
		</div>
		<div class="wrapper flex fade-in" v-infocus="'faded-in'">
			<div>
				<h2>View the history.</h2>
				<p>Take notes and keep track of all your previous measurements.</p>
				<p>The history can also be exported as CSV.</p>
			</div>
			<img
				src="@/assets/screenshots/tinystopwatch-history-2.png"
				style="margin: 2rem 0 2rem 2rem; border-radius: 1rem;"
			/>
		</div>
		<div class="wrapper flex fade-in" v-infocus="'faded-in'">
			<img
				src="@/assets/screenshots/tinystopwatch-shortcuts.png"
				style="margin: 2rem 2rem 2rem 0; border-radius: 1rem;"
			/>
			<div>
				<h2>Pro features.</h2>
				<p>
					Unlock (multiple concurrent) <b style="color: #000;">timers</b>
					and <b style="color: #000;">custom keyboard shortcuts</b>
					with a one-time in-app purchase.
				</p>
			</div>
		</div>
		<a class="download" href="https://apps.apple.com/de/app/tinystopwatch/id1447754003?mt=12" target="_blank" style="margin-top: 100px;">
			<img src="@/assets/macappstore_light.svg" />
		</a>
	</div>
</template>

<script>
export default {
	name: 'TinyStopwatch',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
$primaryColor: #00A1B5;

.mt-100 {
	margin-top: 100px;
}

.app-section.light {
	background: #fff;
	color: #000;

	h1, h2 {
		color: #000;
	}

	.wrapper.flex {
		div {
			p {
				color: rgb(138, 138, 138);
			}
		}
	}

}
</style>
